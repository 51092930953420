// @ts-nocheck
import React, { lazy, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
  useNavigate,
  matchRoutes,
  useLocation
} from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import 'config/firebaseConfig';
//import AuthLayout from "layouts/Auth.js";
//import AdminLayout from "layouts/Admin.js";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
//import "firebase/firestore";
//import 'firebase/compat/firestore';
import { createRoot } from 'react-dom/client';
import { Loadable } from './utils/newPages';

import reducers from 'redux/store/root-reducer';
import { updateActiveNoteId, setFileList } from 'redux/actions';

import { updateUserConfig } from 'redux/slices/userConfig';

import { setRecordLoading } from 'redux/slices/isRecordLoading';

import { loadClients } from 'redux/slices/clients';

import { updateAppConfig } from 'redux/slices/appConfig';

import {
  loginUserWithIdToken,
  interceptorProvider,
  getClients,
  getCollaborator,
  getFileList,
  userRecordsLogin
} from 'redux/actions/api';
import { filterWorkspaceFiles } from 'processor/dataProcessor';

import 'assets/scss/proledger.scss?v=1.9.0';
import { PersistGate } from 'redux-persist/integration/react';
import { setActiveFilePermission } from 'redux/slices/activeFileEditable';
import { setUser, setUserLoading } from 'redux/slices/userReducer';
import { loadCollaborators } from 'redux/slices/collaborators';
import { addError, removeAllError } from 'redux/slices/errorReducer';
import { setInitData } from 'redux/slices/initData';
import { addNotes } from 'redux/slices/notesReducer';
import { ConfirmProvider } from 'material-ui-confirm';

// import { store, persistor } from 'redux/store';

/* const store = applyMiddleware(thunk)(createStore)(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
); */

const store = applyMiddleware(thunk)(createStore)(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
interceptorProvider(store);

const hist = createBrowserHistory();
//window.store = store;
let initNotification = false;
firebase.auth().onAuthStateChanged((user) => {
  /* var a = performance.now();
  console.log("performance: ", a); */
  if (user) {
    if (!firebase.auth().currentUser.emailVerified) {
      if (AllowConfirmEmail()) {
        var email = firebase.auth().currentUser.email;
        localStorage.setItem('verificationEmailFlag', email);
        var actionCodeSettings = {
          url:
            'https://app.pro-ledger.com/auth/login-page?email=' + firebase.auth().currentUser.email
        };
        firebase.auth().currentUser.sendEmailVerification(actionCodeSettings);
      } else {
        //
      }

      store.dispatch(setUserLoading(true));
      store.dispatch(setRecordLoading(true));
      store.dispatch(setUser('unlisted'));
    } else {
      user.getIdToken().then((idToken) => {
        loginUser(idToken);
      });
    }
  } else {
    store.dispatch(setUser(null));
    hist.push({
      pathname: '/auth/login-page'
    });
    initNotification = false;
  }
});

/* firebase
  .firestore()
  .collection("data")
  .doc(
    firebase.auth().currentUser
      ? firebase.auth().currentUser.email
      : "abc@gmail.com"
  )
  .collection("notification")
  .doc("client_notification")
  .onSnapshot((doc) => {
    console.log("notification data: ", doc.data());
  }); */

const AllowConfirmEmail = () => {
  var email = firebase.auth().currentUser.email;
  var storage = localStorage.getItem('verificationEmailFlag');
  if (storage) {
    if (storage === email) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const loginUser = (idToken) => {
  store.dispatch(removeAllError());
  loginUserWithIdToken(idToken)
    .then((response) => {
      /* var b = performance.now();
      console.log("performance: ", b); */
      //console.log(response.data);
      store.dispatch(setInitData(response));
      store.dispatch(updateUserConfig(response.userConfig));
      loadActiveClients(response.clients, response.user);
      store.dispatch(loadCollaborators(response.collaborators));
      store.dispatch(setRecordLoading(true));
      store.dispatch(setUser(response.user));
      store.dispatch(updateAppConfig(response.appConfig.themeColors));
      store.dispatch(addNotes(response.userNotes));
      store.dispatch(updateActiveNoteId(response.userNotes.activeNoteId));
      addNotificationListener();
    })
    .catch((error) => {
      store.dispatch(addError(error));
    });
};

const addNotificationListener = () => {
  try {
    firebase
      .firestore()
      .collection('data')
      .doc(firebase.auth().currentUser ? firebase.auth().currentUser.email : 'abc@gmail.com')
      .collection('notification')
      .doc('client_notification')
      .onSnapshot((doc) => {
        if (doc.data()) {
          if (doc.data().notification.type === 'client') updateClientNotification();
          else if (doc.data().notification.type === 'collaborator')
            updateCollaboratorNotification();
          else if (doc.data().notification.type === 'collaborator_invitation_response') {
            updateCollaboratorNotification();
          }
        }

        initNotification = true;
      });
  } catch (error) {
    console.error(error);
  }
};

const updateClientNotification = () => {
  if (initNotification) {
    getClients()
      .then((cResponse) => {
        let clientsData = cResponse.data;
        store.dispatch(updateClientsData([...clientsData]));
        loadActiveClients(clientsData, store.getState().user.data);

        clientsData.push({
          client_id: 'own',
          client: {
            email: firebase.auth().currentUser.email,
            subscription_status: {
              status: store.getState().user.data.subscription_status.status
            },
            subscription_type: 'expanded'
          }
        });
        const activeWorkspaceId = store.getState().userConfig.workspace.workspaceId;
        const activeClient = clientsData.find((client) => client.client_id === activeWorkspaceId);
        if (activeClient) {
          const activeFileId = store.getState().ledgerFileConfigReducer
            ? store.getState().ledgerFileConfigReducer.file_id
            : '';
          const activeFile = activeClient.files
            ? activeClient.files.find((file) => file.id === activeFileId)
            : null;

          getFileList()
            .then((response) => {
              const filteredList = filterWorkspaceFiles(response.data, activeClient);
              store.dispatch(setFileList(filteredList));
              updateReadWriteState(activeFile);
            })
            .catch((error) => {
              console.error('error in getting files');
            });
        } else {
          store.dispatch(setRecordLoading(true));
          //switchWorkspace(clientsData);
        }
      })
      .catch((cError) => {
        console.error(cError);
      });
  }
};

const updateReadWriteState = (activeFile) => {
  if (activeFile) {
    if (activeFile.permissions.read) {
      if (activeFile.permissions.write) {
        store.dispatch(setActiveFilePermission(true));
      } else {
        store.dispatch(setActiveFilePermission(false));
      }
    }
  } else {
    navigateTo('/admin/file-explorer');
  }
};

const navigateTo = (path) => {
  hist.push({
    pathname: path
  });
};

const updateCollaboratorNotification = () => {
  if (initNotification) {
    getCollaborator()
      .then((response) => {
        //console.log("collaborator: ", response.data);
        store.dispatch(loadCollaborators(response.data));
      })
      .catch((error) => { });
  }
};

const loadActiveClients = (clients, user) => {
  let activeClients = clients.filter((client) => client.status === 'active');
  activeClients.unshift({
    client_id: 'own',
    client: {
      email: firebase.auth().currentUser.email,
      subscription_status: {
        status: user.subscription_status.status
      },
      subscription_type: 'expanded'
    }
  });
  store.dispatch(loadClients(activeClients));
};

const AuthLayout = Loadable(lazy(() => import('./layouts/Auth')));
const AdminLayout = Loadable(lazy(() => import('./layouts/Admin')));
const Lab = Loadable(lazy(() => import('./layouts/Lab')));
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <ConfirmProvider>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="auth/*" element={<AuthLayout />} />
          <Route path="admin/*" element={<AdminLayout />} />
          <Route path="lab/*" element={<Lab />} />
          <Route path="/" element={<Navigate to="/admin/dashboard" />} />
          <Route path="/index.html" element={<Navigate to="/admin/dashboard" />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </ConfirmProvider>
);
